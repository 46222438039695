import { CardsContentElementProps } from "@/components/contentelements/cardsContentElement/cardsContentElement";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import {
  lowerBreakpointDesktopPx,
  lowerBreakpointTabletPx,
  lowerBreakpointWqhdPx,
} from "@/utils/util";
import ViewportPreviewStyles from "../wrapper/viewportPreviewWrapper";

/**
 * This is the scoped style of a CE.
 *
 * @param props
 * @returns
 */

interface CardsContentElementStyleScopedProps extends CardsContentElementProps {
  scopedSelector: string;
}

export default function CardsContentElementStyleScoped(
  props: CardsContentElementStyleScopedProps
) {
  const cardWidth = () => {
    const width = props.content?.cfgCardWidth?.match(/\d+/)?.[0];
    return width ? `${width}%` : "100%";
  };

  const cssValue = (value: number | undefined, defaultValue: string) => {
    if (!value) {
      return defaultValue;
    }
    return value + "px";
  };

  const spaceCardsY = props.ceSettings?.spaceCards.y ?? 0;
  const spaceCardsX = props.ceSettings?.spaceCards.x ?? 0;
  return (
    <>
      <style jsx global>
        {`
          :global(.${props.scopedSelector}.grid) {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(345px, 1fr));
            grid-column-gap: ${spaceCardsX *
            (globalConfig?.layout.scalingfactorSpaceX.mobile ?? 0)}px;

            @media (min-width: ${lowerBreakpointTabletPx()}) {
              grid-column-gap: ${spaceCardsX *
              (globalConfig?.layout.scalingfactorSpaceX.tablet ?? 0)}px;
            }

            @media (min-width: ${lowerBreakpointDesktopPx()}) {
              grid-column-gap: ${spaceCardsX}px;
            }

            @media (min-width: ${lowerBreakpointWqhdPx()}) {
              grid-column-gap: ${spaceCardsX *
              (globalConfig?.layout.scalingfactorSpaceX.wqhd ?? 0)}px;
            }
          }
          :global(.${props.scopedSelector}.grid .cards-addCardContainer) {
            grid-column: 1 / -1;
            align-self: center;
          }
          :global(.${props.scopedSelector}) {
            justify-content: ${props.content.cfgCardAlignment ?? "unset"};

            --bs-gutter-x: ${spaceCardsX *
            (globalConfig?.layout.scalingfactorSpaceX.mobile ??
              0)}px !important;

            @media (min-width: ${lowerBreakpointTabletPx()}) {
              --bs-gutter-x: ${spaceCardsX *
              (globalConfig?.layout.scalingfactorSpaceX.tablet ??
                0)}px !important;
            }

            @media (min-width: ${lowerBreakpointDesktopPx()}) {
              --bs-gutter-x: ${spaceCardsX}px !important;
            }

            @media (min-width: ${lowerBreakpointWqhdPx()}) {
              --bs-gutter-x: ${spaceCardsX *
              (globalConfig?.layout.scalingfactorSpaceX.wqhd ??
                0)}px !important;
            }

            .pb-card-container {
              padding-bottom: ${spaceCardsY *
              (globalConfig?.layout.scalingfactorSpaceY.mobile ?? 0)}px;

              @media (min-width: ${lowerBreakpointTabletPx()}) {
                padding-bottom: ${spaceCardsY *
                (globalConfig?.layout.scalingfactorSpaceY.tablet ?? 0)}px;
              }

              @media (min-width: ${lowerBreakpointDesktopPx()}) {
                padding-bottom: ${spaceCardsY}px;
              }

              @media (min-width: ${lowerBreakpointWqhdPx()}) {
                padding-bottom: ${spaceCardsY *
                (globalConfig?.layout.scalingfactorSpaceY.wqhd ?? 0)}px;
              }

              .pb-card {
                overflow: hidden;
                width: ${cardWidth()};
                margin: auto;
              }

              .general-config-frame {
                height: 100%;
              }
            }

            .pb-card {
              display: flex;
              align-content: stretch;
              ${props.ceSettings?.shadow ?? "box-shadow: unset;"}
              transition: 0.3s;
              ${props.ceSettings?.border ?? "border: 0;"}
              flex-direction: column;
              height: 100%;
            }

            img {
              ${props.ceSettings?.imageBorder ?? "border: 0;"}
            }

            .pb-image {
              overflow: hidden;
            }

            .hover-img-zoom-in:hover img {
              transform: scale(1.2);
              transition: all 0.3s ease;
            }

            .hover-img-zoom-in img {
              transition: all 0.3s ease;
            }

            .pb-card-content {
              margin-top: ${cssValue(props.ceSettings?.textMargin.top, "0")};
              margin-right: ${cssValue(
                props.ceSettings?.textMargin.right,
                "0"
              )};
              margin-bottom: ${cssValue(
                props.ceSettings?.textMargin.bottom,
                "0"
              )};
              margin-left: ${cssValue(props.ceSettings?.textMargin.left, "0")};
              height: 100%;
            }

            .pb-card-actions {
              margin-top: ${cssValue(props.ceSettings?.buttonMargin.top, "0")};
              margin-right: ${cssValue(
                props.ceSettings?.buttonMargin.right,
                "0"
              )};
              margin-bottom: ${cssValue(
                props.ceSettings?.buttonMargin.bottom,
                "0"
              )};
              margin-left: ${cssValue(
                props.ceSettings?.buttonMargin.left,
                "0"
              )};

              .pb-card-button {
                display: flex;
                justify-content: ${props.content.cfgCardButtonAlignment ??
                "center"};
              }

              .mui-button-wrapper a {
                display: flex;
              }

              .mui-button-wrapper {
                max-width: inherit;
              }
            }
          }
        `}
      </style>
      <ViewportPreviewStyles>
        <style>{`
          .${props.scopedSelector}.grid {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(345px, 1fr));
            grid-column-gap: ${
              spaceCardsX *
              (globalConfig?.layout.scalingfactorSpaceX.mobile ?? 0)
            }px;
          }

          @container content-wrapper (min-width: ${lowerBreakpointTabletPx()}) {
            .${props.scopedSelector}.grid {
              grid-column-gap: ${
                spaceCardsX *
                (globalConfig?.layout.scalingfactorSpaceX.tablet ?? 0)
              }px;
            }
          }

          @container content-wrapper (min-width: ${lowerBreakpointDesktopPx()}) {
            .${props.scopedSelector}.grid {
              grid-column-gap: ${spaceCardsX}px;
            }
          }

          @container content-wrapper (min-width: ${lowerBreakpointWqhdPx()}) {
            .${props.scopedSelector}.grid {
              grid-column-gap: ${
                spaceCardsX *
                (globalConfig?.layout.scalingfactorSpaceX.wqhd ?? 0)
              }px;
            }
          }

          .${props.scopedSelector}.grid .cards-addCardContainer {
            grid-column: 1 / -1;
            align-self: center;
          }

          .${props.scopedSelector} {
            justify-content: ${props.content.cfgCardAlignment};
          }

          .${props.scopedSelector} {
            --bs-gutter-x: ${
              spaceCardsX *
              (globalConfig?.layout.scalingfactorSpaceX.mobile ?? 0)
            }px !important;
          }

          @container content-wrapper (min-width: ${lowerBreakpointTabletPx()}) {
            .${props.scopedSelector} {
              --bs-gutter-x: ${
                spaceCardsX *
                (globalConfig?.layout.scalingfactorSpaceX.tablet ?? 0)
              }px !important;
            }
          }

          @container content-wrapper (min-width: ${lowerBreakpointDesktopPx()}) {
            .${props.scopedSelector} {
              --bs-gutter-x: ${spaceCardsX}px !important;
            }
          }

          @container content-wrapper (min-width: ${lowerBreakpointWqhdPx()}) {
            .${props.scopedSelector} {
              --bs-gutter-x: ${
                spaceCardsX *
                (globalConfig?.layout.scalingfactorSpaceX.wqhd ?? 0)
              }px !important;
            }
          }

          .${props.scopedSelector} .pb-card-container {
            padding-bottom: ${
              spaceCardsY *
              (globalConfig?.layout.scalingfactorSpaceY.mobile ?? 0)
            }px;
          }

          @container content-wrapper (min-width: ${lowerBreakpointTabletPx()}) {
            .${props.scopedSelector} .pb-card-container {
              padding-bottom: ${
                spaceCardsY *
                (globalConfig?.layout.scalingfactorSpaceY.tablet ?? 0)
              }px;
            }
          }

          @container content-wrapper (min-width: ${lowerBreakpointDesktopPx()}) {
            .${props.scopedSelector} .pb-card-container {
              padding-bottom: ${spaceCardsY}px;
            }
          }

          @container content-wrapper (min-width: ${lowerBreakpointWqhdPx()}) {
            .${props.scopedSelector} .pb-card-container {
              padding-bottom: ${
                spaceCardsY *
                (globalConfig?.layout.scalingfactorSpaceY.wqhd ?? 0)
              }px;
            }
          }
    `}</style>
      </ViewportPreviewStyles>
    </>
  );
}
